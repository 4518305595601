<template>
    <div>
        <div class="row justify-content-center my-4">
            <div class="" >
                <div class="card">


                    <div class="card-header d-flex justify-content-between">

                        <div>&nbsp;</div>
                            <h4 class="text-center mt-2">
                                {{ $t("edit_profile") }}
                            </h4>
                            <router-link
                                class="btn btn-outline-danger"
                                v-if="isItMe(user)"
                                :to="{ name: 'users.me' }"
                            >
                                X
                            </router-link>

                    </div>
                    <div class="card-body">


                        <Loading v-if="is_loading" />
                        <br />

                        <form @submit.prevent="submitForm">

                                <div class="row">
                                    <div class="form-group col-sm-6">
                                        <EditTextField
                                            type="text"
                                            name=""
                                            :label="$t('first_name')"
                                            placeholder="First Name"
                                            :initval="user.first_name"
                                            icon=" "
                                            :errors="errors"
                                            @update:field="
                                                userData.first_name = $event
                                            "
                                        />
                                    </div>

                                    <div class="form-group col-sm-6 pl-2">
                                        <EditTextField
                                            type="text"
                                            name="surname"
                                            :label="$t('last_name')"
                                            placeholder="Last Name"
                                            :initval="user.surname"
                                            icon=" "
                                            :errors="errors"
                                            @update:field="
                                                userData.surname = $event
                                            "
                                        />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <DepartmentDropdownField
                                            name="department_id"
                                            :label="$t('department_id')"
                                            placeholder="Select department"
                                            :errors="errors"
                                            :initval="user.department_id"
                                            icon="fas fa-building "
                                            @update:field="
                                                userData.department_id = $event
                                            "
                                        />
                                    </div>
                                    <div class="col-sm-6">
                                        <EditTextField
                                            type="number"
                                            name="student_id"
                                            :initval="user.student_id"
                                            :label="$t('student_id')"
                                            :placeholder="$t('student_id')"
                                            icon="fas fa-id-card"
                                            :errors="errors"
                                            @update:field="
                                                userData.student_id = $event
                                            "
                                        />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <EditTextField
                                            type="number"
                                            name="department_batch_number"
                                            :initval="
                                                user.department_batch_number
                                            "
                                            :label="
                                                $t('department_batch_number')
                                            "
                                            :placeholder="
                                                $t('department_batch_number')
                                            "
                                            icon="fas fa-hashtag"
                                            :errors="errors"
                                            @update:field="
                                                userData.department_batch_number =
                                                    $event
                                            "
                                        />
                                    </div>
                                    <div class="col-sm-6">
                                        <EditTextField
                                            type="number"
                                            name="university_batch_number"
                                            :initval="
                                                user.university_batch_number
                                            "
                                            :label="
                                                $t('university_batch_number')
                                            "
                                            :placeholder="
                                                $t('university_batch_number')
                                            "
                                            icon="fas fa-hashtag"
                                            :errors="errors"
                                            @update:field="
                                                userData.university_batch_number =
                                                    $event
                                            "
                                        />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <EditTextField
                                            name="mobile"
                                            type="phone"
                                            :label="$t('mobile')"
                                            placeholder="Mobile"
                                            :initval="user.mobile"
                                            icon="fas fa-mobile"
                                            :errors="errors"
                                            @update:field="
                                                userData.mobile = $event
                                            "
                                        />
                                    </div>

                                    <div class="col-sm-6">
                                        <EditTextField
                                            name="email"
                                            type="email"
                                            :label="$t('email')"
                                            placeholder="E-mail"
                                            :initval="user.email"
                                            icon="fas fa-at"
                                            :errors="errors"
                                            @update:field="
                                                userData.email = $event
                                            "
                                        />
                                    </div>
                                </div>

                                <div class="form-group">
                                    <EditTextField
                                        type="date"
                                        name="dob"
                                        :initval="formattedDob"
                                        :label="$t('dob')"
                                        placeholder="DD/MM/YYYY"
                                        icon="fas fa-birthday-cake"
                                        :errors="errors"
                                        @update:field="userData.dob = $event"
                                    />
                                </div>

                                <div>
                                    <GenderInputField
                                        name="gender"
                                        :label="$t('gender')"
                                        :initval="user.gender"
                                        :errors="errors"
                                        @update:field="userData.gender = $event"
                                    />
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <DropdownField
                                            name="blood_group"
                                            :label="$t('blood_group')"
                                            placeholder="Select blood group"
                                            :options="bloodGroupOptions"
                                            :errors="errors"
                                            :initval="user.blood_group"
                                            icon="fas fa-globe"
                                            @update:field="
                                                userData.blood_group = $event
                                            "
                                        />
                                    </div>
                                    <div class="col-sm-6">
                                        <EditTextField
                                            type="date"
                                            name="last_blood_donated_at"
                                            :initval="
                                                formattedLastBloodDonatedAt
                                            "
                                            :label="$t('last_blood_donated_at')"
                                            placeholder="DD/MM/YYYY"
                                            icon="fas fa-birthday-cake"
                                            :errors="errors"
                                            @update:field="
                                                userData.last_blood_donated_at =
                                                    $event
                                            "
                                        />
                                    </div>
                                </div>



                                <div class="form-group">
                                    <button
                                        type="submit"
                                        class="btn btn-primary"
                                        style="width: 100%"
                                    >
                                        {{ $t("submit") }}
                                    </button>
                                </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EditTextField from "../../components/EditTextField";
import GenderInputField from "../../components/GenderInputField";
import Loading from "../../components/Loading";
import DepartmentDropdownField from "../../components/DepartmentDropdownField";
import DropdownField from "../../components/DropdownField";

export default {
    name: "EditMyProfile",
    props: {
        msg: String,
    },
    components: {
        EditTextField,
        // MobileNumberField,
        GenderInputField,
        // AskForOtcModal,
        Loading,
        // UserPhoto,
        // UploadMyPhotoModal,
        DropdownField,
        DepartmentDropdownField,
    },
    async mounted() {
        this.userData = { ...this.user };
    },
    computed: {
        user() {
            return this.$store.getters.activeUser;
        },
        formattedDob() {
            return this.user.dob == null
                ? null
                : this.formatDate(new Date(this.user.dob), "YYYY-MM-DD", "en");
        },
        formattedLastBloodDonatedAt() {
            return this.user.last_blood_donated_at == null
                ? null
                : this.formatDate(
                      new Date(this.user.last_blood_donated_at),
                      "YYYY-MM-DD",
                      "en"
                  );
        },
    },
    data() {
        return {
            userData: {
                first_name: "",
                surname: "",

                student_id: "",
                department_id: "",
                department_batch_number: "",
                university_batch_number: "",

                mobile: "",
                email: "",

                blood_group: "",
                last_blood_donated_at: "",

                dob: "",
                gender: "",
            },
            errors: null,
            is_loading: false,
            showUploadMyPhotoModal: false,
            bloodGroupOptions: [
                {
                    value: "A+",
                    text: "A+",
                },
                {
                    value: "A-",
                    text: "A-",
                },
                {
                    value: "B+",
                    text: "B+",
                },
                {
                    value: "B-",
                    text: "B-",
                },
                {
                    value: "AB+",
                    text: "AB+",
                },
                {
                    value: "AB-",
                    text: "AB-",
                },
                {
                    value: "O+",
                    text: "O+",
                },
                {
                    value: "O-",
                    text: "O-",
                },
            ],
            formErrors: {}, // Error object
        };
    },
    methods: {
        submitForm() {
            const self = this;
            self.is_loading = true;
            const packet = {
                formData: self.userData,
            };

            console.log("this.userData", JSON.stringify(this.userData));

            self.$store
                .dispatch("updateMe", packet)
                .then(() => {
                    this.$router.push({ name: "users.me" });
                })
                .catch((errors) => {
                    if (
                        errors.response &&
                        errors.response.data &&
                        errors.response.data.errors
                    ) {
                        self.errors = errors.response.data.errors;
                    } else {
                        self.errors = errors;
                    }
                })
                .finally(() => {
                    self.is_loading = false;
                });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
