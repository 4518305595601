<template>
    <div class="form-group">
        <label :for="name" v-if="label" class="text-primary">{{ label }}</label>


        <Loading v-if="is_loading"></Loading>

        <div  v-else class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text" v-if="icon">
                    <i :class="icon"></i>
                </span>
            </div>
            <select
                :name="name"
                class="form-control"
                v-model="value"
                :class="errorClassObject()"
                @change="updateField()"
            >
                <option value="" disabled>{{ placeholder }}</option>
                <option
                    v-for="department in departments"
                    :key="department.id"
                    :value="department.id"
                >
                    {{ department.name }}
                </option>
            </select>
            <div class="input-group-prepend">
                <span class="input-group-text" v-if="icon">
                    {{ value }}
                </span>
            </div>
        </div>
        <p class="text-danger" v-text="errorMessage()"></p>
    </div>
</template>

<script>

import Loading from "@/components/Loading";

export default {
    name: "DepartmentDropdownField",
    components: { Loading },
    props: ["name", "label", "placeholder", "errors", "initval", "icon"],
    async mounted() {
        this.fetchDepartments();
    },
    data() {
        return {
            value: this.initval,
            is_loading:false
        };
    },
    computed: {
        departments() {
            return this.$store.getters.departments;
        },
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            );
        },
    },
    methods: {
        updateField() {
            this.clearErrors(this.name);
            this.$emit("update:field", this.value);
        },
        errorMessage() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        },
        clearErrors() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject() {
            return {
                "error-field": this.hasError,
            };
        },
        fetchDepartments(){

            this.is_loading = true

            this.$store
                .dispatch("fetchDepartments")
                .then((response) => {
                    console.log(response.data);
                })
                .catch(() => {})
                .finally(() => {
                    this.is_loading = false;
                });
        }
    },
    watch: {
        initval: {
            immediate: true,
            handler(newVal) {
                if (newVal !== this.value) {
                    this.value = newVal;
                }
            },
        },
    },
};
</script>

<style scoped>
.error-field {
    @apply border border-danger border-top-0 border-left-0 border-right-0;
}
</style>
