import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import Home from "@/views/home/Home.vue";
import SearchShow from "@/views/home/SearchShow.vue";

import ApiOverview from "@/views/developer/ApiOverview.vue";

import Help from "@/views/about/Help.vue";
import PrivacyPolicy from "@/views/about/PrivacyPolicy.vue";
import ContactUs from "@/views/about/ContactUs.vue";
import About from "@/views/about/About.vue";
// import Login from "@/views/auth/Login.vue";
import LoginContainer from "@/views/auth/LoginContainer.vue";
import Register from "@/views/auth/Register.vue";
// import NewsFeeds from "@/views/home/NewsFeeds.vue";

import SearchUserListView from "@/views/user/SearchUserListView.vue";
import SearchJobListView from "@/views/jobs/SearchJobListView.vue";
import SearchUserBloodGroupListView from "@/views/user/SearchUserBloodGroupListView.vue";
import MyBatchUserListView from "@/views/user/MyBatchUserListView.vue";


import ShowMe from "@/views/user/ShowMe";
import ShowUser from "@/views/user/ShowUser";
import EditMyProfile from "@/views/user/EditMyProfile";
import HomeLeftMenu from "@/views/menus/HomeLeftMenu";

// import UserBadge from "@/views/badges/UserBadge";
import LoginWithOtcToResetPassword from "@/views/auth/LoginWithOtcToResetPassword";
import ResetPassword from "@/views/auth/ResetPassword";

// import i18n from "./i18n";

Vue.use(Router);

let router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: Home,
            leftMenu: HomeLeftMenu,
            // rightMenu: HomeLeftMenu,
            meta: { title: "Home" }
        },
        {
            path: "/alumni",
            name: "alumni",
            component: SearchUserListView,
            leftMenu: HomeLeftMenu,
            meta: { title: "Alumni", requiresAuth: true }
        },
        {
            path: "/jobs",
            name: "jobs",
            component: SearchJobListView,
            leftMenu: HomeLeftMenu,
            meta: { title: "Jobs", requiresAuth: true }
        },
        {
            path: "/blood_group",
            name: "blood_group",
            component: SearchUserBloodGroupListView,
            leftMenu: HomeLeftMenu,
            meta: { title: "Blood Group", requiresAuth: true }
        },

        {
            path: "/alumni/my-batch",
            name: "alumni-mybatch",
            component: MyBatchUserListView,
            leftMenu: HomeLeftMenu,
            meta: { title: "My Batch", requiresAuth: true }
        },

        // {
        //     path: "/users",
        //     name: "users",
        //     component: SearchUserListView,
        //     leftMenu: HomeLeftMenu,
        //     meta: { title: "Users", requiresAuth: true }
        // },
        {
            path: "/about",
            name: "about",
            component: About,
            leftMenu: HomeLeftMenu,
            meta: { title: "About", requiresAuth: false }
        },
        {
            path: "/login",
            name: "login",
            component: LoginContainer,
            meta: { title: "Login" }
        },

        {
            path: "/register",
            name: "register",
            component: Register,
            meta: { title: "Register" }
        },
        {
            path: "/login-with-otc-to-reset-password",
            name: "login-with-otc-to-reset-password",
            component: LoginWithOtcToResetPassword,
            meta: { title: "Reset password" }
        },
        {
            path: "/reset-password",
            name: "reset-password",
            component: ResetPassword,
            meta: { title: "Reset password" }
        },

        {
            path: "/help",
            name: "help",
            component: Help,
            meta: { title: "Help" }
        },
        {
            path: "/privacy-policy",
            name: "privacy-policy",
            component: PrivacyPolicy,
            meta: { title: "Privacy Policy" }
        },
        {
            path: "/contact-us",
            name: "contact-us",
            component: ContactUs,
            meta: { title: "Contact Us" }
        },
        {
            path: "/developers/api-overview",
            name: "api-overview",
            component: ApiOverview,
            meta: { title: "API Overview" }
        },
        {
            path: "/users/me",
            name: "users.me",
            component: ShowMe,
            leftMenu: HomeLeftMenu,
            meta: { title: "Profile", requiresAuth: true }
        },
        {
            path: "/users/edit-me",
            name: "users.edit-me",
            component: EditMyProfile,
            leftMenu: HomeLeftMenu,
            meta: { title: "Edit My Profile", requiresAuth: true }
        },
        {
            path: "/users/:user_id",
            name: "users.show",
            component: ShowUser,
            leftMenu: HomeLeftMenu,
            meta: { title: "User Profile", requiresAuth: true }
        },


        {
            path: "/:username",
            name: "unique-name",
            component: SearchShow,
            leftMenu: HomeLeftMenu,
            // leftBadge: LibraryBadge,
            meta: {
                title: "",
                requiresAuth: true
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }

        const callBackPath = to.path;

        next({ name: "login", query: { from: callBackPath } });
    } else {
        next();
    }
});

export default router;
