import Vue from "vue";
import Vuex from "vuex";
import User from "@/store/user";
import PageTitle from "@/store/page-title";
import Auth from "@/store/auth";
import Post from "@/store/post";
import Language from "@/store/language";
import Comment from "@/store/comment";
import Like from "@/store/like";
import UserPost from "@/store/user-post";
import UserFollower from "@/store/user-follower";
import Follower from "@/store/follower";
import UserFollowing from "@/store/user-following";
import Complain from "@/store/complain.js";
import Block from "@/store/block";
import Departments from "@/store/departments";
import OtcUsernameType from "@/store/otc-username-type";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        User,
        PageTitle,
        Auth,
        Post,
        Language,
        Comment,
        Like,
        UserPost,
        Follower,
        UserFollower,
        UserFollowing,
        Complain,
        Block,
        Departments,
        OtcUsernameType
    },
    state: {},
    getters: {},
    mutations: {},
    actions: {}
});
