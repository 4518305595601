// import Vue from "vue";
import axios from "axios";
import mixin from "../mixin";

const state = {
    otcUsernameType: JSON.parse(localStorage.getItem("otcUsernameType") || null),
};

const getters = {
    otcUsernameType: (state) => state.otcUsernameType,
};

const mutations = {
    setOtcUsernameType(state, otcUsernameType) {
        state.otcUsernameType = otcUsernameType;
    },
};

const actions = {
    fetchOtcUsernameType(context) {
        return new Promise((resolve, reject) => {
            const endPoint = "/api/auth/otc-username-type";

            var url = mixin.methods.getApiUrl(endPoint);

            axios({
                url: url,
                method: "GET",
            })
                .then((response) => {

                    const otcUsernameType = response.data.data;


                    context.commit("setOtcUsernameType", otcUsernameType);

                    resolve(response);
                })
                .catch((err) => {
                    console.log("err:", err);
                    reject(err);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
