<template>
    <div class="card shadow-sm mb-3 no-top-border">
        <!-- <div class="card-header bg-muted text-white">
          <h5 class="mb-0">
            <i class="fas fa-id-badge"></i> {{ $t('profile_information') }}
          </h5>
        </div> -->
        <div class="card-body">
            <div class="text-right" v-if="isItMe(user)">

                <router-link
                              class="btn btn-primary btn-sm"
                                v-if="isItMe(user)"
                                :to="{ name: 'users.edit-me' }"
                            >
                            <i class="fas fa-edit"></i>
                            {{ $t("edit") }}
                            </router-link>

            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                    <strong
                        ><i class="fas fa-id-card text-primary"></i>
                        {{ $t("student_id") }}:&nbsp;</strong
                    >
                    <span class="text-muted">{{ user.student_id }}</span>
                </div>
                <div class="col-md-6">
                    <strong
                        ><i class="fas fa-building text-warning"></i>&nbsp;{{
                            $t("department")
                        }}:&nbsp;</strong
                    >
                    <span class="text-muted">{{ user.department_id }}</span>
                </div>
            </div>

            <div class="row  mb-3">
                <div class="col-md-6">
                    <strong
                        ><i class="fas fa-hashtag text-info"></i>&nbsp;{{
                            $t("department_batch_number")
                        }}:&nbsp;</strong
                    >
                    <span class="text-muted">{{
                        user.department_batch_number
                    }}</span>
                </div>
                <div class="col-md-6">
                    <strong
                        ><i class="fas fa-hashtag text-info"></i>&nbsp;{{
                            $t("university_batch_number")
                        }}:&nbsp;</strong
                    >
                    <span class="text-muted">{{
                        user.university_batch_number
                    }}</span>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                    <strong
                        ><i class="fas fa-tint text-danger"></i>&nbsp;{{
                            $t("blood_group")
                        }}:&nbsp;</strong
                    >
                    <span class="text-muted">{{ user.blood_group }}</span>
                </div>

                <div class="col-md-6">
                    <strong
                        ><i class="	far fa-calendar-alt text-danger"></i>&nbsp;{{
                            $t("last_blood_donated_at")
                        }}:&nbsp;</strong
                    >
                    <span class="text-muted" v-if="this.user.last_blood_donated_at">{{  this.formatDate(
                      new Date(this.user.last_blood_donated_at),
                      "DD MMM YYYY",
                      "en")

                    }}</span>
                </div>

            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                    <strong
                        ><i class="fas fa-phone text-success"></i>&nbsp;{{
                            $t("mobile")
                        }}:&nbsp;</strong
                    >
                    <span class="text-muted"> <a :href="'tel:' + user.mobile">{{ user.mobile }}</a> </span>
                </div>
                <div class="col-md-6">
                    <strong
                        ><i class="fas fa-envelope text-danger"></i>&nbsp;{{
                            $t("email")
                        }}:&nbsp;</strong
                    >
                    <span class="text-muted"> <a :href="'mailto:' + user.email">{{ user.email }}</a></span>
                </div>
            </div>


            <div class="row mb-3">
                <div class="col-md-6" >
                    <strong
                        ><i class="fas fa-birthday-cake text-warning"></i>&nbsp;{{
                            $t("dob")
                        }}:&nbsp;</strong
                    >
                    <span class="text-muted" v-if="this.user.dob && this.isItMe(this.user)">{{ this.formatDate(
                      new Date(this.user.dob),
                      "DD MMM YYYY",
                      "en") }}</span>
                </div>
                <div class="col-md-6">
                </div>

            </div>


        </div>
    </div>
</template>

<script>
export default {
    name: "UserAbout",
    computed: {
        user() {
            if(this.isItMe(this.$store.getters.user)){
                return this.$store.getters.activeUser;
            }
            else{
                return this.$store.getters.user;
            }
        },
    },
};
</script>

<style>
.no-top-border {
    border-top: none !important;
}
</style>
