<template>
    <div class="about">
        <br />
        <div class="p-2 m-2 d-flex justify-content-around">
            <img
                src="@/assets/images/defaults/logo_large.png"
                height="180"
                alt="DUET Circle logo"
            />

            <img
                src="@/assets/images/duet_circle_4.png"
                height="200"
                alt="DUET Circle"
            />
        </div>

        <h1>DUET Circle</h1>
        <p>
            <strong>DUET Circle</strong> একটি অনলাইন প্ল্যাটফর্ম যেখানে ঢাকা
            প্রকৌশল ও প্রযুক্তি বিশ্ববিদ্যালয় (DUET) এর প্রাক্তন শিক্ষার্থীরা
            একত্রিত হতে পারে। এই প্ল্যাটফর্মের মাধ্যমে প্রাক্তন শিক্ষার্থীরা একে
            অপরের সাথে যোগাযোগ করতে, পেশাদার নেটওয়ার্ক তৈরি করতে এবং নিজেদের
            সফলতার গল্প শেয়ার করতে পারে। <strong>DUET Circle</strong> এর মূল
            উদ্দেশ্য হল DUET পরিবারকে পুনরায় সংযুক্ত করা, ব্যক্তিগত ও পেশাদার
            জীবনে সহায়ক নেটওয়ার্ক গড়ে তোলা, এবং বিশ্ববিদ্যালয়ের সাথে
            প্রাক্তন শিক্ষার্থীদের সম্পর্ককে আরও দৃঢ় করা। এখানে শিক্ষার্থীরা
            বিভিন্ন ইভেন্ট, কর্মশালা ও কর্মসংস্থান সংক্রান্ত তথ্য পেতে পারেন, যা
            তাদের ক্যারিয়ার এবং ব্যক্তিগত উন্নয়নে সহায়ক হবে।
        </p>

        <h2>আমাদের কার্যক্রম:</h2>
        <ul>
            <li>
                <strong>কমিউনিটি:</strong> DUET-এর প্রাক্তন শিক্ষার্থীদের জন্য
                একটি শক্তিশালী কমিউনিটি তৈরি করতে সহায়তা করে।
            </li>
            <li>
                <strong>ক্যারিয়ার:</strong> প্রাক্তন শিক্ষার্থীরা চাকরির সুযোগ
                এবং পেশাদার নেটওয়ার্কের মাধ্যমে তাদের ক্যারিয়ার উন্নত করতে
                পারেন।
            </li>
            <li>
                <strong>দক্ষতা উন্নয়ন:</strong> বিভিন্ন কর্মশালা ও কোর্সের
                মাধ্যমে প্রয়োজনীয় দক্ষতা অর্জনের সুযোগ।
            </li>
            <li>
                <strong>ইভেন্ট:</strong> প্রাক্তন শিক্ষার্থীদের জন্য বিশেষ
                ইভেন্ট এবং পুনর্মিলনীর আয়োজন।
            </li>
            <li>
                <strong>মেন্টরশিপ:</strong> নবীনদের জন্য অভিজ্ঞ প্রাক্তন
                শিক্ষার্থীদের মেন্টরশিপ সুবিধা।
            </li>
            <li>
                <strong>রক্তের গ্রুপ ব্যবস্থাপনা:</strong> জরুরি রক্তের প্রয়োজন
                হলে প্রাক্তন শিক্ষার্থীদের মধ্যে রক্তদাতাদের খুঁজে পাওয়ার একটি
                কার্যকর ব্যবস্থা।
            </li>
        </ul>

        <br />
        <br />
        <br />
    </div>
</template>

<script>
export default {
    name: "AboutBn",
    components: {},
    props: {},
    mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
