// import Vue from "vue";
import axios from "axios";
import mixin from "../mixin";

const state = {
    departments: JSON.parse(localStorage.getItem("departments") || null),

};

const getters = {
    departments: (state) => state.departments,
};

const mutations = {
    setDepartments(state, departments) {
        state.departments = departments;
    },
};

const actions = {
    fetchDepartments(context) {
        return new Promise((resolve, reject) => {
            const endPoint = "/api/departments";

            var url = mixin.methods.getApiUrl(endPoint);

            axios({
                url: url,
                method: "GET",
            })
                .then((response) => {

                    const departments = response.data.data;

                    context.commit("setDepartments", departments);

                    resolve(response);
                })
                .catch((err) => {
                    console.log("err:", err);
                    reject(err);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
