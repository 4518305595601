<template>
    <div>
        <!-- <h4 class="text-secondary"> {{ $t('alumni') }} </h4> -->

        <SearchTextField

        :placeholder="$t('search_fellows')"
        @update:term="term = $event" />

    </div>
</template>

<script>



import SearchTextField from "@/components/SearchTextField";


export default {
    name: "SearchJobListView",
    components: {

        SearchTextField,
            },
    async mounted() {

    },
    data: function() {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
    watch: {

    }
};
</script>
