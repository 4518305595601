import { render, staticRenderFns } from "./EditMyProfile.vue?vue&type=template&id=6aaa549f&scoped=true"
import script from "./EditMyProfile.vue?vue&type=script&lang=js"
export * from "./EditMyProfile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aaa549f",
  null
  
)

export default component.exports