<template>
    <div class="">
        <div
            id="carouselExampleIndicators"
            class="carousel slide bg-light py-4"
            data-ride="carousel"
        >
            <ol class="carousel-indicators">
                <li
                    v-for="(item, index) in items"
                    v-bind:key="item.title"
                    v-bind:class="{ active: item === activeItem }"
                    :data-slide-to="index"
                    data-target="#carouselExampleIndicators"
                ></li>
            </ol>
            <div class="carousel-inner">
                <div
                    class="carousel-item text-center"
                    v-for="item in items"
                    v-bind:key="item.title"
                    v-bind:class="{ active: item === activeItem }"
                >
                    <img
                        :src="item.image_name"
                        :alt="item.title"
                    class="w-75 img-fluid"
                />
            </div>
        </div>

        <div class="text-center">
            <div
                class="d-flex flex-column align-items-center justify-content-center mb-3"
            >
                <h3 class="fw-bold text-success mb-0">DUET Circle</h3>
                <h4 class="text-secondary mb-0">
                    &nbsp;-&nbsp;Connects DUET alumni worldwide.
                </h4>
            </div>
        </div>
    </div>
    </div>
</template>

<script>

import duet_circle_4 from "@/assets/images/duet_circle_4.png";
import duet_circle_6 from "@/assets/images/duet_circle_6.png";

export default {
    name: "TopSlider",
    components: {},
    mounted: function () {
        this.activeItem = this.items[0];
    },
    computed: {},
    props: {},
    data() {
        return {
            items: [
                // {
                //     title: "smart_app_dev_training_2014_title",
                //     caption: "smart_app_dev_training_2014_caption",
                //     image_name: bm_with_palak
                // },
                {
                    // title: "welcome_duet_circle_title",
                    // caption: "welcome_duet_circle_caption",
                    image_name: duet_circle_6,
                },
                {
                    // title: "welcome_duet_circle_title",
                    // caption: "welcome_duet_circle_caption",
                    image_name: duet_circle_4,
                },
                // {
                //     title: "award_basis_softexpo_2023_title",
                //     caption: "award_basis_softexpo_2023_caption",
                //     image_name: award_basis_softexpo_2023
                // },
                // {
                //     title: "district_champion_title",
                //     caption: "district_champion_caption",
                //     image_name: award_patuakkhali
                // },
                // {
                //     title: "barishal_innovation_fair_2023_title",
                //     caption: "barishal_innovation_fair_2023_caption",
                //     image_name: achivement_innovation_fair_barisal_2023
                // },
            ],
            activeItem: null,
        };
    },
    methods: {
        showItem(item) {
            this.activeItem = item;
        },
    },
};
</script>
