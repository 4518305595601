<template>
    <div>
        <div class="card mb-3 p-3">
    <div class="d-flex align-items-center">
        <UserPhoto :user="item" size="64" class="mx-4"/>

        <div>
            <h5 class="mb-1 font-weight-bold">{{ item.first_name }} {{ item.surname }}</h5>
            <p class="mb-1 text-muted">
                <i class="fas fa-id-card  text-primary"></i> {{ item.student_id }}<br />
                <i class="fas fa-building  text-warning"></i> {{ item.department_id }}<br />
                <i class="fas fa-tint  text-danger"></i> {{ item.blood_group }}<br />
                <!-- <i class="fas fa-phone"></i> {{ item.mobile }}<br />
                <i class="fas fa-envelope"></i> {{ item.email }} -->
            </p>

            <router-link
                :to="{
                    name: 'users.show',
                    params: { user_id: item.id }
                }"
                class="stretched-link"
            >
            </router-link>
        </div>
    </div>
</div>

    </div>
</template>

<script>
import UserPhoto from "@/views/user/UserPhoto";

export default {
    name: "UserListItem",
    props: ["item"],
    components: {
        UserPhoto,
    },
    methods: {
        photoUrl() {
            return this.getApiUrl(this.item.photo_url);
        },
    },
};
</script>
