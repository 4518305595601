<template>
    <div>
        <footer class="border-top border-bottom p-4">
            <div id="bars" class="d-flex flex-wrap justify-content-between">
                <div class="text-center p-2 mr-3 port-item">
                    <router-link :to="{ name: 'home' }">
                        <img
                            alt="Logo of DUET Circle"
                            src="@/assets/images/defaults/logo_large.png"
                            width="72"
                        />
                    </router-link>
                </div>

                <div class="flex-fill port-item mb-3">
                    <div class="mr-4 border-bottom font-weight-bold">
                        {{ $t("app_name") }}
                    </div>
                    <div>
                        <router-link :to="{ name: 'about' }">
                            {{ $t("about") }}
                        </router-link>
                    </div>
                </div>

                <div class="flex-fill port-item mb-3">
                    <div class="mr-4 border-bottom font-weight-bold">
                        {{ $t("community") }}
                    </div>
                    <div>
                        <router-link :to="{ name: 'privacy-policy' }">
                            {{ $t("privacy_policy") }}
                        </router-link>
                    </div>
                    <div>
                        <router-link :to="{ name: 'contact-us' }">
                            {{ $t("contact_us") }}
                        </router-link>
                    </div>
                </div>

                <div class="port-item mb-3">
                    <div class="ml-2">
                        {{ $t("download_the_app") }}
                    </div>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.alorferi.duetcircle"
                        target="_blank"
                    >
                        <img
                            src="@/assets/images/defaults/google_play_get_on.png"
                            width="200"
                            alt="DUET Circle logo"
                        />
                    </a>
                    <div class="mt-1 ml-2">
                        {{ $t("get_us_on") }}
                        <a
                            class="p-1"
                            href="https://fb.com/duetcircle"
                            target="_blank"
                        >
                            <img
                                src="@/assets/images/icons/ic_facebook.png"
                                width="32"
                                alt="DUET Circle Facebook"
                            />
                        </a>
                        <a
                            class="p-1"
                            href="https://youtube.com/@duetcircle"
                            target="_blank"
                        >
                            <img
                                src="@/assets/images/icons/ic_youtube.png"
                                width="32"
                                alt="DUET Circle YouTube"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </footer>

        <div class="text-center border-bottom py-2">
            Copyright ©
            <a href="https://alorferi.com">Alor Feri Limited</a> | Powered by
            <a href="https://alorferi.com">Alor Feri Limited</a>
        </div>
    </div>
</template>

<script>
// import LanguageToggleLink from "@/components/LanguageToggleLink";

export default {
    name: "Login",
    props: {
        msg: String,
    },
    components: {
        // LanguageToggleLink
    },
    mounted: function () {},
    data() {
        return {};
    },
    methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen and (max-width: 768px) {
    #bars {
        flex-direction: column !important;
    }
    .port-item {
        width: 100%;
    }
}
</style>
