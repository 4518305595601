<template>
    <div>
        <div class="list-group mt-2">
            <a
                href="/"
                class="list-group-item list-group-item-action"
                @click="reload"
            >
                {{ $t("news_feed") }}</a
            >

            <router-link
                class="list-group-item list-group-item-action"
                :to="{ name: 'alumni-mybatch' }"
            >{{ $t("my_classmates") }}
            </router-link>

            <a
                class="list-group-item list-group-item-action"
                href="https://play.google.com/store/apps/details?id=com.alorferi.duetcircle"
                target="_blank"
            >  <img src="@/assets/images/defaults/icon_google_play.png"
            width="24" alt=""> {{ $t("download") }}
        </a>

            <!-- <router-link
      class="list-group-item list-group-item-action"
      :to="{ name: 'autocomplete' }"
    >Autocomplete</router-link> -->

            <!-- <router-link
      :to="{ name: 'library-create' }"
      class="list-group-item list-group-item-action"
    >Create Library</router-link> -->

            <!-- <a href="#" class="list-group-item list-group-item-action">Messages</a> -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: "HomeLeftMenu",
    components: {},
    computed: {
        firstThreeLettersOfStudentId() {
            const studentId = this.$store.getters.activeUser.student_id;
            const firstThreeLetters = studentId.substring(0, 3);

            return firstThreeLetters;
        },
    },
    methods: {
        reload: function () {
            this.$router.go(this.$router.currentRoute);
        },
    },
};
</script>
