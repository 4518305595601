<template>
    <div id="app" class="">
        <nav
            class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm bg-white"
            v-if="isLoggedIn"
        >
            <div class="container">
                <HomeNav />
            </div>
        </nav>

        <nav
            class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm bg-white"
            v-else
        >
            <div class="container">
                <GuestNav />
            </div>
        </nav>

        <div class="container" style="margin-top: 70px">
            <div v-if="isLoggedIn">
                <div v-if="leftBadge || leftMenu || rightMenu">
                    <div class="row">
                        <div class="col-sm-2">
                            <component :is="leftBadge"></component>
                            <component :is="leftMenu"></component>
                        </div>
                        <!-- style="overflow-y: scroll;" -->
                        <div class="col-sm-10">
                            <router-view></router-view>
                        </div>
                        <!-- <div class="col-sm-2">
                            <component :is="rightMenu"></component>
                        </div> -->
                    </div>
                </div>

                <div v-else>
                    <router-view></router-view>
                </div>
            </div>

            <div v-else class="">
                <router-view></router-view>
            </div>

            <BottomBar />
        </div>
    </div>
</template>

<script>
import GuestNav from "@/views/navbars/GuestNav";
import HomeNav from "@/views/navbars/HomeNav.vue";
// import UserBadge from "./views/badges/UserBadge";
// import HomeLeftMenu from "./views/menus/HomeLeftMenu";
// import LibraryLeftMenu from "./views/menus/LibraryLeftMenu";
import BottomBar from "@/views/navbars/BottomBar";

export default {
    name: "App",
    components: {
        GuestNav,
        HomeNav,
        BottomBar,
    },
    computed: {
        isLoggedIn: function () {
            return this.$store.getters.isLoggedIn;
        },
        leftBadge() {
            var name = this.$route.name;
            var routes = this.$router.options.routes.filter(function (route) {
                return route.name == name;
            });
            if (routes.length == 1) {
                return routes[0].leftBadge;
            }

            return null;
        },
        leftMenu() {
            var name = this.$route.name;
            var routes = this.$router.options.routes.filter(function (route) {
                return route.name == name;
            });
            if (routes.length == 1) {
                return routes[0].leftMenu;
            }

            return null;
        },
        rightMenu() {
            var name = this.$route.name;
            var routes = this.$router.options.routes.filter(function (route) {
                return route.name == name;
            });
            if (routes.length == 1) {
                return routes[0].rightMenu;
            }

            return null;
        },
    },
    methods: {},
    created() {
        this.$i18n.locale = this.$store.getters.activeLanguage;
        this.$store.dispatch("setPageTitle", this.$route.meta.title);
    },
    watch: {
        $route(to) {
            this.$store.dispatch("setPageTitle", to.meta.title);
        },
    },
    goBack() {
        window.history.length > 1
            ? this.$router.go(-1)
            : this.$router.push({
                  name: "home",
              });
    },
    metaInfo() {
        return {
            title: "DUET Circle - Connect, Collaborate, and Grow",
            meta: [
                // Standard Meta Tags
                {
                    name: "description",
                    content:
                        "DUET Circle is an exclusive social network for DUET alumni to connect, collaborate, and grow through professional networking, events, and career opportunities.",
                },
                {
                    name: "keywords",
                    content:
                        "DUET, DUET Alumni, DUET Circle, Alumni Network, Professional Networking",
                },
                { name: "author", content: "DUET Circle" },

                // Open Graph Meta Tags
                {
                    property: "og:title",
                    content:
                        "DUET Circle - Connect, Collaborate, and Grow with DUET Alumni",
                },
                {
                    property: "og:description",
                    content:
                        "Join DUET Circle, the community network connecting DUET alumni with opportunities for networking, collaboration, and professional development.",
                },
                { property: "og:type", content: "website" },
                { property: "og:url", content: "https://www.duetcircle.com" },
                {
                    property: "og:image",
                    content: require("@/assets/images/defaults/og-image.jpg"),
                },
                {
                    property: "og:image:alt",
                    content: "DUET Circle - Connect with DUET Alumni",
                },
                { property: "og:site_name", content: "DUET Circle" },
                { property: "og:locale", content: "en_US" },

                // Twitter Meta Tags
                { name: "twitter:card", content: "summary_large_image" },
                { name: "twitter:url", content: "https://www.duetcircle.com" },
                {
                    name: "twitter:title",
                    content:
                        "DUET Circle - Connect, Collaborate, and Grow with DUET Alumni",
                },
                {
                    name: "twitter:description",
                    content:
                        "DUET Circle is the exclusive social network for DUET alumni, offering professional networking, events, and career opportunities.",
                },
                {
                    name: "twitter:image",
                    content:
                        "https://www.duetcircle.com/assets/images/twitter-image.jpg",
                },
            ],
        };
    },
};
</script>

<style></style>
