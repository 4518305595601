<template>
    <div >


        <div class="row mt-5">
    <div class="col-md-8">
        <TopSlider class="mb-4" />
    </div>
    <div class="col-md-4">
        <Login/>
    </div>
</div>





    </div>
</template>

<script>

import TopSlider from "@/views/welcome/TopSlider";
import Login from "@/views/auth/Login";


export default {
    name: "Welcome",
    components: {
        TopSlider,
        Login
    },
    mounted: function() {},
    computed: {},
    props: {
        msg: String
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>
