<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-6 my-4">
                <div class="card my-4">
                    <div class="card-header">
                        <h4 class="text-center">Reset Password</h4>
                    </div>

                    <div class="card-body">
                        <form
                            id="loginWithOtcForm"
                            class="otc_enabled_form"
                            @submit.prevent="submitForm"
                        >
                            <div class="form-group mt-4 mb-4">
                                <UserNameField
                                    name="username"
                                    label=""
                                    :placeholder="$t(otcUsernameTypeHint)"
                                    icon="fas fa-at"
                                    :errors="errors"
                                    @update:field="
                                        resetPasswordModel.username = $event
                                    "
                                />
                            </div>

                            <div
                                class="text-center text-danger mb-2"
                                v-if="error_message"
                            >
                                {{ error_message }}
                            </div>

                            <AskForOtcModal
                                :showOtcModal="showOtcModal"
                                :durationInSeconds="durationInSeconds"
                                :props_message="message"
                                @updateOtc="updateOtc"
                                @onUpdateVisibleState="showOtcModal = $event"
                            />

                            <div class="form-group">
                                <button
                                    type="submit"
                                    class="btn btn-success btn-block"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <LoadingModal
                :showLoadingModal="showLoadingModal"
                @onUpdateVisibleState="showLoadingModal = $event"
            />
        </div>
    </div>
</template>

<script>
import UserNameField from "../../components/UserNameField";
import AskForOtcModal from "../../components/AskForOtcModal";

import LoadingModal from "@/components/LoadingModal";

export default {
    name: "LoginWithOtcToResetPassword",
    components: {
        UserNameField,
        AskForOtcModal,
        LoadingModal,
    },
    async mounted() {
      this.fetchOtcUsernameType();
    },

    computed: {
        otcUsernameTypeHint() {
            switch (this.$store.getters.otcUsernameType) {
                case "mobile":
                    return "type_your_mobile_here";
                case "email":
                    return "type_your_email_here";
                case "mobile_or_email":
                    return "type_your_mobile_or_email_here";
            }

            return null;
        },
    },
    data() {
        return {
            resetPasswordModel: {
                otc_type_name: "RESET_PASSWORD",
                username: "",
            },
            ot_code: "",
            durationInSeconds: 0,
            message: "",
            showOtcModal: false,
            showAlertModal: true,
            showLoadingModal: false,
            errors: null,
            error_message: null,
        };
    },

    methods: {
        updateOtc(value) {
            this.ot_code = value;
            this.submitForm();
        },
        submitForm: function () {
            console.log(this.resetPasswordModel);
            const self = this;

            const headers = {
                "ot-code": self.ot_code == null? '' : self.ot_code,
                "Content-Type": "application/json",
            };

            console.log(headers)

            self.showLoadingModal = true;
            this.$store
                .dispatch("loginWithOtc", {
                    headers: headers,
                    data: this.resetPasswordModel,
                })
                .then((response) => {
                    self.showLoadingModal = false;
                    console.log(response.data.data);

                    switch (response.data.status) {
                        case "OK":
                            self.ot_code = "";
                            self.$router.replace({ name: "reset-password" });
                            break;
                        case "OTC_GENERATED":
                        case "OTC_REJECTED":
                            var otc_expired_after_in_seconds =
                                response.data.data.otc_expired_after_in_seconds;
                            self.durationInSeconds =
                                otc_expired_after_in_seconds;
                            this.message = response.data.message;
                            self.showOtcModal = true;

                            console.log(response.data.message);

                            break;
                    }

                    self.ot_code = "";
                })
                .catch((errors) => {
                    self.showLoadingModal = false;
                    self.ot_code = "";
                    console.log(errors);

                    try {
                        if (errors.response.data.errors) {
                            self.errors = errors.response.data.errors;
                        } else if (errors.response.data.message) {
                            this.error_message = errors.response.data.message;
                        }
                    } catch (err) {
                        self.errors = err;
                    }

                    self.ot_code = null;
                });
        },
        fetchOtcUsernameType: function() {
            this.$store
                .dispatch("fetchOtcUsernameType")
                .then(() => {

                })
                .catch(() => {})
                .finally(() => {

                });
        },
    },
};
</script>
