<template>
    <div class="about">
        <br />

        <div class="p-2 m-2 d-flex justify-content-around">
            <img
                src="@/assets/images/defaults/logo_large.png"
                height="180"
                alt="DUET Circle logo"
            />

            <img
                src="@/assets/images/duet_circle_4.png"
                height="200"
                alt="DUET Circle"
            />
        </div>

        <h2>DUET Circle (English Description)</h2>
        <p>
            <strong>DUET Circle</strong> is an online platform designed for the
            alumni of Dhaka University of Engineering & Technology (DUET) to
            reconnect, network, and share their success stories. Through this
            platform, alumni can engage with fellow graduates, build
            professional connections, and stay informed about university news
            and events. The primary goal of <strong>DUET Circle</strong> is to
            strengthen the bond among DUET alumni, support their professional
            and personal growth, and maintain a strong connection with the
            university. Alumni can access information about events, workshops,
            and job opportunities that can aid in their career and personal
            development.
        </p>

        <h2>Our Activities:</h2>
        <ul>
            <li>
                <strong>Community:</strong> Helps build a strong community among
                DUET alumni.
            </li>
            <li>
                <strong>Career:</strong> Alumni can advance their careers
                through job opportunities and professional networking.
            </li>
            <li>
                <strong>Skill Development:</strong> Access to workshops and
                courses for essential skill enhancement.
            </li>
            <li>
                <strong>Events:</strong> Special events and reunions organized
                for alumni.
            </li>
            <li>
                <strong>Mentorship:</strong> Mentorship opportunities from
                experienced alumni for newcomers.
            </li>
            <li>
                <strong>Blood Group Management:</strong> An effective system for
                finding blood donors among alumni in emergencies.
            </li>
        </ul>

        <br />
        <br />
        <br />
    </div>
</template>

<script>
// import WhatWeDo from "@/views/about/WhatWeDo";

export default {
    name: "AboutEn",
    components: {
        // WhatWeDo,
    },
    props: {},
    mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
