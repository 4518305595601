<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-6 my-4">
                <Login />
            </div>
        </div>
    </div>
</template>

<script>
import Login from "@/views/auth/Login";
// import $ from 'jquery'

// import jQuery from 'jquery'

export default {
    name: "LoginContainer",
    props: {
        msg: String,
    },
    components: {
        Login,
    },
    mounted: function () {
        if (this.$store.getters.isLoggedIn) {
            if (this.$route.query.from) {
                this.$router.push(this.$route.query.from);
            } else {
                this.$router.push({ name: "home" });
            }
        }
    },
    computed: {},
    data() {
        return {};
    },
    methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
