var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[(_vm.likes.length == 1)?_c('div',{staticClass:"p-1"},[_c('a',{staticClass:"btn btn-link",on:{"click":function($event){_vm.showLikesModal = !_vm.showLikesModal}}},[_c('i',{staticClass:"far fa-thumbs-up text-primary"}),(_vm.myLike != null)?_c('span',[_vm._v(" You ")]):_c('span',[_vm._v(" "+_vm._s(_vm.likes[0].attributes.user.first_name)+" ")])])]):(_vm.likes.length == 2)?_c('div',{staticClass:"p-1"},[_c('a',{staticClass:"btn btn-link",on:{"click":function($event){_vm.showLikesModal = !_vm.showLikesModal}}},[_c('i',{staticClass:"far fa-thumbs-up text-primary"}),_vm._v(" "+_vm._s(_vm.textForTwoLikes)+" ")])]):(_vm.likes.length >= 3)?_c('div',{staticClass:"p-1"},[_c('a',{staticClass:"btn btn-link",on:{"click":function($event){_vm.showLikesModal = !_vm.showLikesModal}}},[_c('i',{staticClass:"far fa-thumbs-up text-primary"}),_vm._v(" "+_vm._s(_vm.textForGreatterThanOrEqualThreeLikes)+" ")])]):_c('div')]),_c('div',[(_vm.comments.length > 0)?_c('div',{staticClass:"p-1"},[_c('i',{staticClass:"far fa-comment-dots"}),_vm._v(" "+_vm._s(_vm.comments.length)+" comments ")]):_vm._e()])]),_c('div',{staticClass:"d-flex justify-content-between border border-left-0 border-right-0 border-bottom-0 pt-1"},[_c('div',[_c('button',{staticClass:"btn",class:{
                    'btn-light': !_vm.myLike,
                    'btn-outline-primary': _vm.myLike,
                    'border-bottom-0': _vm.myLike,
                    'border-top-0': _vm.myLike,
                    'border-left-0': _vm.myLike,
                    'border-right-0': _vm.myLike
                },attrs:{"type":"button"},on:{"click":_vm.onClikcLike}},[_c('i',{staticClass:"far fa-thumbs-up"}),_vm._v(" Like ")])]),_vm._m(0)]),(_vm.is_loading)?_c('Loading'):_vm._l((_vm.comments),function(comment){return _c('CommentListItem',{key:comment.attributes.id,attrs:{"comment":comment.attributes,"post":_vm.post}})}),_c('CreatePostComment',{attrs:{"post":_vm.post}}),_c('PostLikesModal',{attrs:{"show":_vm.showLikesModal,"post":_vm.post},on:{"updateVisibleState":function($event){_vm.showLikesModal = $event}}})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn btn-light"},[_c('i',{staticClass:"far fa-comment-dots"}),_vm._v(" Comment")])
}]

export { render, staticRenderFns }