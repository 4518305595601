<template>
    <div class="form-group">
        <label :for="name" v-if="label" class="text-primary">{{ label }}</label>

        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text" v-if="icon">
                    <i :class="icon"></i>
                </span>
            </div>
            <select
                :name="name"
                class="form-control"
                v-model="value"
                :class="errorClassObject()"
                @change="updateField()"
            >
                <option value="" disabled>{{ placeholder }}</option>
                <option v-for="option in options" :key="option.value" :value="option.value">
                    {{ option.text }}
                </option>
            </select>
        </div>
        <p class="text-danger" v-text="errorMessage()"></p>
    </div>
</template>

<script>
export default {
    name: "DropdownField",
    props: ["name", "label", "placeholder", "options", "errors", "initval", "icon"],
    data() {
        return {
            value: this.initval
        };
    },
    computed: {
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            );
        }
    },
    methods: {
        updateField() {
            this.clearErrors(this.name);
            this.$emit("update:field", this.value);
        },
        errorMessage() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        },
        clearErrors() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject() {
            return {
                "error-field": this.hasError
            };
        }
    },
    watch: {
        initval: {
            immediate: true,
            handler(newVal) {
                if (newVal !== this.value) {
                    this.value = newVal;
                }
            }
        }
    }
};
</script>

<style scoped>
.error-field {
    @apply border border-danger border-top-0 border-left-0 border-right-0;
}
</style>
