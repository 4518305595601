var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"media border p-3 mb-3"},[_c('router-link',{attrs:{"to":{
                name: 'users.show',
                params: { user_id: this.post.user.id },
            }}},[_c('UserPhoto',{attrs:{"user":_vm.post.user,"size":"48"}})],1),_c('div',{staticClass:"media-body pl-3"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('div',[_c('router-link',{staticClass:"text-dark",attrs:{"to":{
                                name: 'users.show',
                                params: { user_id: this.post.user.id },
                            }}},[_c('b',[_vm._v(" "+_vm._s(_vm.post.user.first_name)+" "+_vm._s(_vm.post.user.surname)+" ")])]),(
                                _vm.post.postable_type == 'App\\Models\\Library'
                            )?_c('span',[_vm._v(" > "),_c('router-link',{staticClass:"text-dark",attrs:{"to":{
                                    name: 'library-show',
                                    params: { id: _vm.post.postable.id },
                                }}},[_c('b',[_vm._v(" "+_vm._s(_vm.post.postable.name)+" ")])])],1):_vm._e()],1),_c('small',[_c('i',{staticClass:"text-text-secondary"},[_vm._v(" "+_vm._s(this.momentFromNow(_vm.post.created_at)))])])]),_c('div',[_c('div',{staticClass:"dropdown show"},[_c('a',{staticClass:"dropdown-toggle dropdown-toggle-split btn btn-link border-top border-left border-right border-bottom",attrs:{"role":"button","id":"dropdownMenuLink","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}}),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuLink"}},[(_vm.isMyPost)?_c('a',{staticClass:"dropdown-item text-primary",attrs:{"role":"button"},on:{"click":function($event){_vm.showEditPostModal = !_vm.showEditPostModal}}},[_c('i',{staticClass:"far fa-edit"}),_vm._v(" Edit this post")]):_vm._e(),(_vm.isMyPost)?_c('a',{staticClass:"dropdown-item text-danger",attrs:{"role":"button"},on:{"click":function($event){return _vm.deleteUserPost(_vm.post)}}},[_c('i',{staticClass:"far fa-trash-alt"}),_vm._v(" Delete this post")]):_vm._e(),(!_vm.isMyPost)?_c('a',{staticClass:"dropdown-item text-danger",attrs:{"role":"button"},on:{"click":function($event){_vm.showCreateReportModel =
                                        !_vm.showCreateReportModel}}},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" Report this post")]):_vm._e(),(!_vm.isMyPost)?_c('a',{staticClass:"dropdown-item text-danger",attrs:{"role":"button"},on:{"click":function($event){_vm.showCreateBlockModel =
                                        !_vm.showCreateBlockModel}}},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" Block this post")]):_vm._e()])])])]),_c('CreateReportModal',{attrs:{"show":_vm.showCreateReportModel,"user":_vm.post.user,"complainable_type":"Post","complainable_id":_vm.post.id},on:{"updateVisibleState":function($event){_vm.showCreateReportModel = $event}}}),_c('CreateBlockModal',{attrs:{"show":_vm.showCreateBlockModel,"user":_vm.post.user,"blockable_type":"Post","blockable_id":_vm.post.id},on:{"updateVisibleState":function($event){_vm.showCreateBlockModel = $event},"didFinish":_vm.didFinishBlockingUser}}),(_vm.post.title)?_c('h5',{staticClass:"mr-5",staticStyle:{"text-align":"justify"},domProps:{"innerHTML":_vm._s(_vm.post.title)}}):_vm._e(),(_vm.post.body)?_c('p',{staticClass:"mr-5",staticStyle:{"text-align":"justify"},domProps:{"innerHTML":_vm._s(this.parsedBody(_vm.post.body))}}):_vm._e(),(_vm.post.image_url)?_c('div',{staticClass:"mr-5"},[_c('img',{staticClass:"img-fluid",staticStyle:{"max-width":"100%","height":"auto"},attrs:{"src":this.getApiUrl(_vm.post.image_url),"alt":"Posted image"}})]):_vm._e(),(_vm.getYouTubeEmbedUrl)?_c('div',{staticClass:"embed-responsive embed-responsive-16by9"},[_c('iframe',{staticClass:"embed-responsive-item",attrs:{"src":_vm.getYouTubeEmbedUrl}})]):_vm._e(),_c('ShowPostComments',{attrs:{"post":_vm.post}}),_c('EditPostModal',{attrs:{"show":_vm.showEditPostModal,"post":_vm.post,"postable_type":_vm.post.postable_type},on:{"updateVisibleState":function($event){_vm.showEditPostModal = $event}}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }