<template>
    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-6 my-4">
                <div class="card my-4">
                    <h4 class="card-header text-center">
                        {{ $t("create_new_account") }}
                    </h4>

                    <div class="card-body">
                        <form class="card-body" @submit.prevent="submitForm">
                            <div class="row">
                                <div class="col-sm-6">
                                    <EditTextField
                                        type="text"
                                        name="first_name"
                                        :label="$t('first_name')"
                                        :placeholder="$t('first_name')"
                                        icon=" "
                                        :errors="errors"
                                        @update:field="
                                            userData.first_name = $event
                                        "
                                    />
                                </div>

                                <div class="col-sm-6">
                                    <EditTextField
                                        type="text"
                                        name="surname"
                                        :label="$t('last_name')"
                                        :placeholder="$t('last_name')"
                                        icon=" "
                                        :errors="errors"
                                        @update:field="
                                            userData.surname = $event
                                        "
                                    />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <DepartmentDropdownField
                                        name="department_id"
                                        :label="$t('department_id')"
                                        placeholder="Select department"
                                        :errors="errors"
                                        :initval="selectedDepartmentId"
                                        icon="fas fa-building "
                                        @update:field="
                                            userData.department_id = $event
                                        "
                                    />
                                </div>
                                <div class="col-sm-6">
                                    <EditTextField
                                        type="number"
                                        name="student_id"
                                        :initval="this.userData.student_id"
                                        :label="$t('student_id')"
                                        :placeholder="$t('student_id')"
                                        icon="fas fa-id-card"
                                        :errors="errors"
                                        @update:field="
                                            userData.student_id = $event
                                        "
                                    />
                                </div>
                            </div>

                            <div class="mb-3">
                                <UserNameField
                                    name="username"
                                    :label="$t('mobile')"
                                    :placeholder="$t(otcUsernameTypeHint)"
                                    icon="fas fa-at"
                                    :errors="errors"
                                    @update:field="userData.username = $event"
                                />
                            </div>

                            <div class="mb-3">
                                <PasswordField
                                    name="password"
                                    :label="$t('password')"
                                    :placeholder="$t('password')"
                                    icon="fas fa-key"
                                    :errors="errors"
                                    @update:field="userData.password = $event"
                                />
                            </div>

                            <!--
                        <div class="mb-3">
                            <EditTextField
                                type="date"
                                name="dob"
                                :initval="this.userData.dob"
                                :label="$t('dob')"
                                placeholder="DD/MM/YYYY"
                                icon="fas fa-birthday-cake"
                                :errors="errors"
                                @update:field="userData.dob = $event"
                            />
                        </div>

                        -->

                            <div class="mb-3">
                                <GenderInputField
                                    name="gender"
                                    :label="$t('gender')"
                                    :errors="errors"
                                    @update:field="userData.gender = $event"
                                />
                            </div>

                            <div class="mb-3">
                                <AskForOtcModal
                                    :showOtcModal="showOtcModal"
                                    :durationInSeconds="durationInSeconds"
                                    :props_message="message"
                                    @updateOtc="updateOtc"
                                    @onUpdateVisibleState="
                                        showOtcModal = $event
                                    "
                                />
                                <button
                                    type="submit"
                                    class="btn btn-primary btn-block"
                                >
                                    {{ $t("submit") }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EditTextField from "../../components/EditTextField";
import DepartmentDropdownField from "../../components/DepartmentDropdownField";
import PasswordField from "../../components/PasswordField";
import UserNameField from "../../components/UserNameField";
import GenderInputField from "../../components/GenderInputField";
import AskForOtcModal from "../../components/AskForOtcModal";

// import $ from 'jquery'

// import jQuery from 'jquery'

export default {
    name: "Register",
    props: {
        msg: String,
    },
    components: {
        EditTextField,
        PasswordField,
        GenderInputField,
        UserNameField,
        AskForOtcModal,
        DepartmentDropdownField,
    },
    async mounted() {
        if (this.$store.getters.isLoggedIn) {
            this.$router.push({ name: "home" });
        }
        this.fetchOtcUsernameType();
    },
    computed: {
        otcUsernameTypeHint() {
            switch (this.$store.getters.otcUsernameType) {
                case "mobile":
                    return "type_your_mobile_here";
                case "email":
                    return "type_your_email_here";
                case "mobile_or_email":
                    return "type_your_mobile_or_email_here";
            }

            return null;
        },
    },
    data() {
        return {
            userData: {
                first_name: "",
                surname: "",
                username: "",
                password: "",
                // dob: this.formatDate(new Date(), "YYYY-MM-DD", "en"),
                gender: "",
            },
            // username: "",
            ot_code: "",
            errors: null,
            showOtcModal: false,
            durationInSeconds: 30,
            message: "",
            selectedDepartmentId: "", // Initially selected value
            formErrors: {}, // Error object
        };
    },
    methods: {
        updateOtc(value) {
            this.ot_code = value;
            this.submitForm();
        },

        login: function () {
            const self = this;
            let username = this.userData.username;
            let password = this.userData.password;
            this.$store
                .dispatch("loginBasic", { username, password })
                .then(() => {
                    this.$store
                        .dispatch("fetchMe")
                        .then(() => {
                            self.$router.replace({ name: "home" });
                            console.log("success");
                        })
                        .catch(() => {
                            console.log("failed");
                        });
                })
                .catch((err) => {
                    this.error_message = err;
                    console.log(err);
                    this.is_error = true;
                });
        },

        submitForm: function () {
            const self = this;
            const url =
                this.$apiServerBaseUrl + "/api/auth/register-with-username";

            const headers = {
                "ot-code": self.ot_code,
            };

            // if(this.isMobileNumber(this.username)){
            //     this.userData.mobile = this.username;
            //     this.userData.email = ""
            // }else{
            //     this.userData.email = this.username;
            //     this.userData.mobile = "";
            // }

            //console.log("this.userData", this.userData);

            console.log("this.userData", JSON.stringify(this.userData));

            this.$axios
                .post(url, this.userData, {
                    headers: headers,
                })
                .then((response) => {
                    console.log(response.data.data);

                    switch (response.data.status) {
                        case "OK":
                            // self.$router.push({ name: "login" });
                            // this.$route.path = "/auth/login";

                            self.login();

                            break;
                        case "OTC_GENERATED":
                        case "OTC_REJECTED":
                            var otc_expired_after_in_seconds =
                                response.data.data.otc_expired_after_in_seconds;
                            this.durationInSeconds =
                                otc_expired_after_in_seconds;
                            this.message = response.data.message;
                            this.showOtcModal = true;

                            break;
                    }

                    // this.$router.push("/auth/login");
                })
                .catch((errors) => {
                    console.log(errors);
                    try {
                        if (errors.response.data.errors) {
                            self.errors = errors.response.data.errors;
                        }
                    } catch (err) {
                        self.errors = err;
                    }

                    this.ot_code = "";
                });
        },
        fetchOtcUsernameType: function() {
            this.$store
                .dispatch("fetchOtcUsernameType")
                .then(() => {

                })
                .catch(() => {})
                .finally(() => {

                });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
