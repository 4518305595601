<template>
    <div class=" border-left border-right ">
        <div class="jumbotron text-center" style="margin-bottom:0">
            <h1 class="text-center">Contact Us</h1>
            <h4>We are here for you</h4>
        </div>

        <div class="p-4">
            <h5>
                Address
            </h5>
            <div>
                <p>
                    DUET Circle
                </p>

                <p>
                    241/A, North Kazipara
                </p>

                <p>
                    Kafrul, Mirpur, Dhaka - 1216
                </p>

                <div>
                    <a href="https://maps.app.goo.gl/vurpymDtkKBywiiM9" target="_blank"> <i class='fas fa-map-marker-alt' style='color:red'></i> View Map</a>
                </div>
            </div>
        </div>

        <div class="p-4">
            <h5>
                Mobile
            </h5>
            <div>
                Call us:  <a href="tel:+8801717983473">+880 1717 983 473</a>

            </div>
        </div>

        <div class="p-4">
            <h5>
                E-mail
            </h5>
            <div>
                Email us:  <a href = "mailto:info@duetcircle.com">info@duetcircle.com</a>
            </div>
        </div>
        <div class="p-4">
            <h5>
                Facebook
            </h5>
            <div>
                Send us a direct message from our
                <a href="https://www.facebook.com/duetcircle" target="_blank"
                    >Facebook Page</a
                >
            </div>
        </div>







    </div>
</template>

<script>
export default {
    name: "ContactUs",
    components: {},
    mounted: function() {},
    computed: {},
    props: {},
    data() {
        return {};
    },
    methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
